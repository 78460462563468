// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
//= require jquery
//= require jquery_ujs
//= require rails-ujs

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "chartkick/chart.js";

if (Rails) Rails.start();
else console.log("WARNING: in application.js, Rails undefined");
Turbolinks.start();
ActiveStorage.start();
